import { Plugin } from '@nuxt/types'
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { logger } from '~nuxt-feature-flags/logger'
// @ts-ignore
import { FeatureFlagsClient } from '~nuxt-feature-flags/client/feature-flags-client'
// @ts-ignore
import { FeatureFlagsStorage } from '~nuxt-feature-flags/client/feature-flags-storage'

const plugin: Plugin = async (ctx, inject) => {
  const parsedOptions = JSON.parse('{"enabledDefault":false,"ttl":60000,"requestTimeout":5000,"url":"https://scm.alao.ch/api/v4/feature_flags/unleash/57","instanceId":"glffct-ycYY9qpx421kvyxg8aqT","environment":"release","projectFeatures":["maintenance_mode_enabled","feature_new_gtm_debug_mode","feature_winter_promo_theme","feature_october_promo_theme","feature_black_friday","feature_black_september","feature_black_april","feature_summer_deals","feature_valentine_theme","feature_spring_theme","feature_easter_theme","feature_may_theme","feature_summer_theme","feature_new_main_banner","feature_b2b_page","feature_new_verify_id_process","feature_new-year","feature_new-voucher-process","feature_amp_addresses","feature_amp_base_info","feature_amp_comparator","feature_hubspot_chat","feature_dixa_chat","feature_deals_of_the_day_widget","feature_static_exit_intent_popup","feature_iframe_offer_card_page"],"baseURL":"https://www.release.alao.ch","useRedisStore":false,"redisConnection":{"socket":{"host":"alao-frontend-redis","port":6379,"connectTimeout":3000},"database":1,"disableOfflineQueue":true}}')

  const storage = FeatureFlagsStorage.create()
  const featureFlags = await FeatureFlagsClient.create(parsedOptions, storage, logger).bootstrap()

  inject('featureFlags', featureFlags)
  ctx.app.featureFlags = featureFlags

  // For debugging purposes
  if (process.client) {
    (window as any).featureFlags = () => {
      const features = parsedOptions.projectFeatures.map((featureName: string) => {
        const feature = featureFlags.list.find((item: any) => item.name === featureName)

        if (feature) {
          return {
            name: feature.name,
            enabled: feature.enabled,
            description: feature.description,
          }
        }

        return {
          name: featureName,
          enabled: parsedOptions.enabledDefault,
          description: null,
        }
      })

      console.table(features)
    }
  }
}

export default plugin
